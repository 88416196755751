import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import { linkPropType } from 'lib/prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

const ProAndCon = props => {
  return (
    <Fragment>
      <div className="section section--padded-big">
        <div className="section__inner tc">
          <h2 className="headline">{props.title}</h2>
          <div className="columns__box">
            <div
              dangerouslySetInnerHTML={{ __html: props.text }}
              className="column--third tl"
            />
            <div className="column--third tl">
              <div>
                {props.pros ? (
                  <ul className="list__pro-con">
                    {props.pros.map((pro, i) => (
                      <li key={`pros-${i}`}>
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="icons__pro-con icon--pro"
                        />
                        {pro}
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
              <div>
                {props.cons ? (
                  <ul className="list__pro-con">
                    {props.cons.map((con, i) => (
                      <li key={`cons-${i}`}>
                        <FontAwesomeIcon
                          icon={faMinus}
                          className="icons__pro-con icon--con"
                        />
                        {con}
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </div>
            <div className="column--third">
              {props.image ? (
                <img
                  src={props.image.url}
                  alt={props.image.description}
                  className="pro-con__img"
                />
              ) : null}
              {props.link ? (
                <Button
                  link={props.link}
                  className="btn--primary db margin--t2"
                >
                  {props.link.label}
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

ProAndCon.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  pros: PropTypes.arrayOf(PropTypes.string),
  cons: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  link: linkPropType,
}

export default ProAndCon
