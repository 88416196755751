import React from 'react'
import PropTypes from 'prop-types'
import { urlLocationPropType, linkPropType } from 'lib/prop-types'
import Link from 'components/Link'
import parseToUrl from 'lib/parse-to-url'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import contentfulUrlForLink from 'lib/contentful-url-for-link'
import Helmet from 'react-helmet'
import structuredData from '../../../lib/structured-data'

const Breadcrumbs = props => {
  let trail = [
    {
      label: typeof window == 'undefined' ? '' : window.location.host,
      link: '/',
    },
  ]
  if (props.links) {
    trail.push(
      ...props.links.map(link => {
        return { label: link.label, link: contentfulUrlForLink(link) }
      })
    )
  } else if (props.trail) {
    trail.push(
      ...props.trail.map((label, i) => {
        return {
          label,
          link:
            '/' +
            props.trail
              .slice(0, i + 1)
              .map(link => parseToUrl(link))
              .join('/'),
        }
      })
    )
  } else {
    const labels = (props.location?.pathname || '')
      .split('/')
      .filter(level => level.length > 0)
    trail.push(
      ...labels.map((label, i) => {
        return { label, link: '/' + labels.slice(0, i + 1).join('/') }
      })
    )
  }

  return (
    <div className="section">
      <div className="section__inner">
        <div className="breadcrumbs text--smaller">
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(structuredData.breadcrumbs(trail))}
            </script>
          </Helmet>
          {trail.map(({ label, link }, i) => {
            return (
              <span key={`crumb-${i}`} className="breadcrumb__item">
                {i === 0 ? null : (
                  <FontAwesomeIcon icon={faChevronRight} className="ph2" />
                )}

                <div className="breadcrumb">
                  {i < trail.length - 1 ? (
                    <Link to={link}>{label}</Link>
                  ) : (
                    label
                  )}
                </div>
              </span>
            )
          })}
        </div>
      </div>
    </div>
  )
}

Breadcrumbs.propTypes = {
  location: urlLocationPropType,
  trail: PropTypes.arrayOf(PropTypes.string),
  links: PropTypes.arrayOf(linkPropType),
}

export default Breadcrumbs
