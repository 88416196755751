import React from 'react'
import PropTypes from 'prop-types'
import { linkPropType } from 'lib/prop-types'
import Button from 'components/Button'
import Link from 'components/Link'
import contentfulImage from 'lib/contentful-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faAngleRight } from '@fortawesome/free-solid-svg-icons'

const Hero = props => {
  const cta = props.links && props.links[0]
  const links = props.links?.slice(1) || []

  const backgroundImage = contentfulImage(props.image.url, {
    width: 1240,
    format: 'jpg',
    quality: 70,
  })

  let size
  if (props.size === 'Klein') {
    size = 'small'
  } else if (props.size === 'Mittel') {
    size = 'medium'
  } else {
    size = 'large'
  }

  return (
    <div className="section__hero">
      <div
        className={`section__inner section__inner--bg color-white hero__${size}`}
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="section__hero--inner">
          {props.badge ? (
            <Link
              to={props.badge.page?.slug || props.badge.url}
              className="badge__hero link"
            >
              <FontAwesomeIcon icon={faInfoCircle} className="mr2" />
              {props.badge.label}
              <FontAwesomeIcon icon={faAngleRight} className="ml3" />
            </Link>
          ) : null}
          <h1 className="hero__title text-shadow">{props.title}</h1>
          <h2 className="hero__text text-shadow mt2 mb4">{props.teaser}</h2>
          {/* render CTA Button */}
          <div>
            {cta ? (
              <p className="dib-ns mr3-ns">
                <Button link={cta} className="btn--primary">
                  {cta.label}
                </Button>
              </p>
            ) : null}
            {/* render additional links */}
            {links.length > 0
              ? links.map(link => {
                  return (
                    <p key={`hero-${link.id}`} className="dib-ns mr1">
                      <Button link={link} className="btn--white">
                        {link.label}
                      </Button>
                    </p>
                  )
                })
              : null}
          </div>
        </div>
        {props.overlayImage ? (
          <div className="hero__overlay">
            <img
              src={contentfulImage(props.overlayImage.url)}
              alt={props.overlayImage.description}
              width="100%"
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  teaser: PropTypes.string,
  image: PropTypes.object,
  overlayImage: PropTypes.object,
  links: PropTypes.arrayOf(linkPropType),
  badge: linkPropType,
  size: PropTypes.oneOf(['Groß', 'Mittel', 'Klein']),
}

export default Hero
