module.exports = (productVariant, image) => {
  if (typeof image === 'undefined') {
    if (typeof productVariant.defaultImage === 'undefined') {
      return ''
    }
    image = productVariant.defaultImage
  }

  return `product/${productVariant.id}-${image.name}`
}
