import React from 'react'
import { withPrefix } from 'gatsby'
import PropTypes from 'prop-types'

const Image = props => {
  let fileName
  if (props.size) {
    fileName = `${props.name}-${props.size}`
  } else {
    fileName = `${props.name}`
  }
  return (
    <picture className={props.className}>
      <source
        srcSet={withPrefix(`/images/${fileName}.webp`)}
        type="image/webp"
      />
      <source srcSet={withPrefix(`/images/${fileName}.png`)} type="image/png" />
      <img src={withPrefix(`/images/${fileName}.png`)} alt={props.alt || ''} />
    </picture>
  )
}

export default Image

Image.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  alt: PropTypes.string,
}
