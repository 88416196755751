import React from 'react'
import PropTypes from 'prop-types'

const Categories = ({ categories, className, namespace }) => {
  if (categories.length === 0) return null

  const categoryNames = categories.map(category => {
    if (typeof category === 'string') return category
    return category.name || category.value
  })

  return (
    <div className={`product__categories ${className}`}>
      {categoryNames.map(category => {
        return (
          <div key={`${namespace}-${category}`} className="categorie--item">
            <span className="categorie--label">{category}</span>
          </div>
        )
      })}
    </div>
  )
}

export default Categories

Categories.propTypes = {
  categories: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  className: PropTypes.string,
  namespace: PropTypes.string.isRequired,
}
