import React from 'react'
import PropTypes from 'prop-types'
import { linkPropType } from 'lib/prop-types'
import Markdown from 'components/Markdown'
import contentfulImage from 'lib/contentful-image'
import Button from 'components/Button'

const IntroAndPicture = props => {
  let imageUrl
  let description

  if (props.image) {
    imageUrl = contentfulImage(props.image.url, {
      width: 1100,
      quality: 75,
    })
    description = props.image.description
  }

  return (
    <div className="section section--padded-big">
      <div className="section__inner tc">
        <h2 className="headline">{props.title}</h2>
        {imageUrl ? (
          <div className="column--measure-medium center tc margin--v2">
            <img src={imageUrl} alt={description} />
          </div>
        ) : null}
        <Markdown className="column--measure-medium center">
          {props.text}
        </Markdown>
        {props.link ? (
          <Button link={props.link} className="btn btn--primary">
            {props.link.label}
          </Button>
        ) : null}
      </div>
    </div>
  )
}

IntroAndPicture.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  link: linkPropType,
}

export default IntroAndPicture
