import React from 'react'
import PropTypes from 'prop-types'

const Markdown = props => {
  let className = ['js-md pre-wrap']
  if (props.className) className.push(props.className)

  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.children }}
      className={className.join(' ')}
    />
  )
}

export default Markdown

Markdown.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}
