import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  configurationPropType,
  productPropType,
  siteDataPropType,
} from 'lib/prop-types'
import Helmet from 'react-helmet'
import Image from 'components/Image'
import formatMoney from 'lib/format-money'
import Link from 'components/Link'
import SiteMetadata from 'lib/SiteMetadata'
import createLink from 'lib/create-link'
import Categories from 'components/Products/Categories'
import parseToUrl from 'lib/parse-to-url'
import imageUrlForProduct from 'lib/image-url-for-product'
import {
  itemList,
  product as productStructuredData,
} from '../../../lib/structured-data'

const getLongCategory = categories => {
  return categories.map(category => category.value).join(' ')
}

const filter = (filters, products) => {
  const { category, manufacturer } = filters
  if (!category && !manufacturer) {
    return products
  }
  const filteredProducts = { metaProducts: [] }
  products.metaProducts.forEach(metaProduct => {
    const filteredProductVariants = []
    metaProduct.productVariants.forEach(productVariant => {
      if (!manufacturer || productVariant.manufacturer === manufacturer) {
        const productCategory = getLongCategory(productVariant.inCategories)
        if (!category || productCategory === category) {
          filteredProductVariants.push(productVariant)
        }
      }
    })
    if (filteredProductVariants.length > 0) {
      filteredProducts.metaProducts.push(metaProduct)
    }
  })
  return filteredProducts
}

class ProductList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      products: filter(
        { category: props.category, manufacturer: props.manufacturer },
        this.props.products
      ),
    }

    this.manufacturers = this.props.configuration.manufacturers
      .map(manufacturer => manufacturer.name)
      .filter(
        manufacturer =>
          !props.manufacturer || manufacturer === props.manufacturer
      )
      .sort((a, b) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1
        if (a.toLowerCase() > b.toLowerCase()) return 1
        return 0
      })
    this.siteMetadata = props.data.site.siteMetadata
  }

  render() {
    const { products } = this.state
    return (
      <Fragment>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(
              itemList(
                this.state.products.metaProducts.flatMap(metaProduct =>
                  metaProduct.productVariants.map(productVariant =>
                    productStructuredData(productVariant, {
                      siteUrl: this.siteMetadata.siteUrl,
                    })
                  )
                )
              )
            )}
          </script>
        </Helmet>
        <div className="section bg__flex--grey">
          <div className="section__inner">
            <div className="product__list">
              {products.metaProducts.length > 0 ? (
                this.manufacturers.map(manufacturer => {
                  return products.metaProducts.map(metaProduct => {
                    if (
                      metaProduct.productVariants.some(productVariant => {
                        return productVariant.manufacturer === manufacturer
                      })
                    ) {
                      const productCategories = []
                      metaProduct.productVariants.forEach(productVariant => {
                        productVariant.inCategories.forEach(category => {
                          if (!productCategories.includes(category.value)) {
                            productCategories.push(category.value)
                          }
                        })
                      })
                      return (
                        <div
                          key={`meta-${metaProduct.productVariants[0].id}`}
                          className="product__list--item b-r--3"
                        >
                          <div className="manufacturer-logo">
                            <Image
                              name={`manufacturer/${parseToUrl(manufacturer)}`}
                              size="large"
                            />
                          </div>
                          <Categories
                            categories={productCategories}
                            namespace={`productDetail-${metaProduct.productVariants[0].id}`}
                          />
                          <div className="product__variants">
                            {metaProduct.productVariants.map(productVariant => {
                              return (
                                <Link
                                  key={productVariant.id}
                                  to={createLink.toProduct(productVariant)}
                                  className="product__variants--item hover--shadow b-r--3"
                                >
                                  {productVariant.defaultImage ? (
                                    <div className="product-img--tiny b-r--3">
                                      <Image
                                        name={imageUrlForProduct(
                                          productVariant
                                        )}
                                        size="small"
                                        alt={productVariant.defaultImage.alt}
                                      />
                                    </div>
                                  ) : null}
                                  {productVariant.price === 0 ? null : (
                                    <div className="product__variants--price">
                                      <div className="text--smallest fw--regular">
                                        exkl. MwSt.
                                      </div>
                                      {formatMoney(productVariant.price)}
                                    </div>
                                  )}
                                  <div className="product__variants--title">
                                    {productVariant.title}
                                  </div>
                                </Link>
                              )
                            })}
                          </div>
                        </div>
                      )
                    }
                  })
                })
              ) : (
                <div className="empty-state__container">
                  <p className="column--measure center">
                    Es wurden keine Ergebnisse mit diesen Filtereinstellungen
                    gefunden.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

ProductList.propTypes = {
  products: productPropType,
  configuration: configurationPropType,
  data: siteDataPropType,
  category: PropTypes.string,
  manufacturer: PropTypes.string,
}

const ProductListSite = props => (
  <SiteMetadata render={data => <ProductList data={data} {...props} />} />
)

export default ProductListSite
