const parseToUrl = require('./parse-to-url')

module.exports = {
  toProduct(product) {
    const categories = product.inCategories.map(category => category.value)

    if (
      !product.manufacturer ||
      !product.title ||
      !categories ||
      categories.length === 0
    ) {
      return `/product-${product.id.toLowerCase()}`
    }

    let url = `/${parseToUrl(categories[0])}/`

    if (categories.length > 1) {
      categories.slice(1).forEach(category => {
        url += `${parseToUrl(category)}-`
      })
    }

    // replace the last dash with a slash
    url = url.slice(0, -1) + '/'

    url += parseToUrl(product.manufacturer) + '-' + parseToUrl(product.title)

    return url
  },
}
