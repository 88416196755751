const createLink = require('./create-link')
const createLongTitle = require('./create-long-title')

module.exports = {
  breadcrumbs: trail => {
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: trail.map(({ label, link }, i) => ({
        '@type': 'ListItem',
        position: i + 1,
        name: label,
        item: `https://www.heizungsmacher.ch${link}`,
      })),
    }
  },
  jobPosting: job => {
    return {
      '@context': 'https://schema.org/',
      '@type': 'JobPosting',
      title: job.title,
      description: job.text,
      employmentType: job.workload,
      datePosted: job.updatedAt,
      jobLocation: {
        '@type': 'Place',
        address: job.jobLocation,
      },
      hiringOrganization: {
        '@context': 'https://schema.org/',
        '@type': 'Organization',
        name: 'Heizungsmacher AG',
      },
    }
  },
  itemList: list => {
    return {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      itemListElement: list,
    }
  },
  product: (productVariant, { siteUrl }) => {
    return {
      '@context': 'https://schema.org',
      '@type': 'Product',
      name: createLongTitle(productVariant),
      image: `${siteUrl}/images/product/${productVariant.id}.png`,
      description: productVariant.description,
      brand: productVariant.manufacturer,
      offers: {
        price: productVariant.price,
        priceCurrency: 'CHF',
        url: `${siteUrl}${createLink.toProduct(productVariant)}`,
      },
    }
  },
  organisation: siteUrl => {
    return {
      '@context': 'https://schema.org',
      '@type': 'HomeAndConstructionBusiness',
      name: 'Heizungsmacher AG',
      url: siteUrl,
      logo: `${siteUrl}/icons/hm-logo-blue.svg`,
      image: `${siteUrl}/icons/hm-logo-blue.svg`,
      location: [
        {
          '@type': 'HomeAndConstructionBusiness',
          parentOrganization: {
            name: 'Heizungsmacher AG',
          },
          name: 'Heizungsmacher AG – Basel',
          address: {
            '@type': 'PostalAddress',
            streetAddress: 'Aeschenvorstadt 71',
            postalCode: '4051',
            addressLocality: 'Basel',
            addressCountry: 'Schweiz',
          },
          openingHours: 'Mo-Fr 08:00-17:00',
          telephone: '+41 61 322 32 00',
          image: `${siteUrl}/icons/hm-logo-blue.svg`,
        },
        {
          '@type': 'HomeAndConstructionBusiness',
          parentOrganization: {
            name: 'Heizungsmacher AG',
          },
          name: 'Heizungsmacher AG – Zürich',
          address: {
            '@type': 'PostalAddress',
            streetAddress: 'Konradstrasse 32',
            postalCode: '8005',
            addressLocality: 'Zürich',
            addressCountry: 'Schweiz',
          },
          openingHours: 'Mo-Fr 08:00-17:00',
          telephone: '+41 44 312 32 00',
          image: `${siteUrl}/icons/hm-logo-blue.svg`,
        },
        {
          '@type': 'HomeAndConstructionBusiness',
          parentOrganization: {
            name: 'Heizungsmacher AG',
          },
          name: 'Heizungsmacher AG – Aarau',
          address: {
            '@type': 'PostalAddress',
            streetAddress: 'Laurenzenvorstadt 25',
            postalCode: '5000',
            addressLocality: 'Aarau',
            addressCountry: 'Schweiz',
          },
          openingHours: 'Mo-Fr 08:00-17:00',
          telephone: '+41 62 822 32 00',
          image: `${siteUrl}/icons/hm-logo-blue.svg`,
        },
        {
          '@type': 'HomeAndConstructionBusiness',
          parentOrganization: {
            name: 'Heizungsmacher AG',
          },
          name: 'Heizungsmacher AG – Sursee',
          address: {
            '@type': 'PostalAddress',
            streetAddress: 'Wassergrabe 14',
            postalCode: '6210',
            addressLocality: 'Sursee',
            addressCountry: 'Schweiz',
          },
          openingHours: 'Mo-Fr 08:00-17:00',
          telephone: '+41 41 360 32 00',
          image: `${siteUrl}/icons/hm-logo-blue.svg`,
        },
        {
          '@type': 'HomeAndConstructionBusiness',
          parentOrganization: {
            name: 'Heizungsmacher AG',
          },
          name: 'Heizungsmacher AG – Solothurn',
          address: {
            '@type': 'PostalAddress',
            streetAddress: 'Grabackerstrasse 6',
            postalCode: '4500',
            addressLocality: 'Solothurn',
            addressCountry: 'Schweiz',
          },
          openingHours: 'Mo-Fr 08:00-17:00',
          telephone: '+41 62 822 32 00',
          image: `${siteUrl}/icons/hm-logo-blue.svg`,
        },
        {
          '@type': 'HomeAndConstructionBusiness',
          parentOrganization: {
            name: 'Heizungsmacher AG',
          },
          name: 'Heizungsmacher AG – Bern',
          address: {
            '@type': 'PostalAddress',
            streetAddress: 'Spitalgasse 28',
            postalCode: '3011',
            addressLocality: 'Bern',
            addressCountry: 'Schweiz',
          },
          openingHours: 'Mo-Fr 08:00-17:00',
          telephone: '+41 31 305 32 00',
          image: `${siteUrl}/icons/hm-logo-blue.svg`,
        },
      ],
    }
  },
}
